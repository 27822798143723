import React from 'react';
import Logo from '../resources/images/logotype.svg';
export const Header = () => {
  return (
    <div className="row color-app-bg mh-15 min-vw-90 mw-90 min-vh-15 py-2 container-app-header">
      <div className="row justify-content-between px-5 " style={{fontSize:16}}>

        Traking Clientes
 
        <div className="col-3 col-xs-4 col-sm-4 col-md-1 col-lg-1 mh-80">
          <figure>
            <img src={Logo} className="img-fluid rounded d-block m-l-none" alt="GEOMIL" >
            </img>
          </figure>

        </div>

      </div>



    </div>
  )
};

export default Header;
