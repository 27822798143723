import axios from 'axios';

//const base_url = `http://localhost/matchsports-backend/public/`;
  // const base_url = `https://pulpo.digital/matchsports-backend/public/index.php/`;
const base_url = `https://portal.repseg.com/`;
 
const API = axios.create({
  baseURL: base_url,
});

export default API;