import React from 'react'
import OrderDetail from './OrderDetail';
import Footer from './Footer'
const Order = () => {
    return (
        <div className="row container-app-body  bg-white">
            <div className='row  container-tracking p-2'>
                
                    <OrderDetail></OrderDetail>
 
            </div>
          
        </div>
    )
}
export default Order;