import React, { useEffect, useState } from 'react';
import useApi from '../hooks/useApi' 

const OrderDetail = () => {

    const [state, setState] = useState({
        'guide': '',
        'error_message': '',
        'modal_error_show': false,
        'info_data': false,
        'data': [],
        'count': 0


    });

    useEffect(() => {
        localStorage.removeItem("guide_barcodetext");

    }, []);

    const { guide, error_message, modal_error_show, count } = state;
    const handleSubmit = (e) => {
        try {
            e.preventDefault();

            if (state.guide === '') {
                setState({ ...state, modal_error_show: true, error_message: "Debe ingresar un número de guía para realizar una búsqueda" });
            } else {

                let res = useApi.get_by_plate(state.guide).then(res => {
                    console.log('res');
                    console.log(res, state.guide);
                    state.info_data = true;
                    state.guide = '';


                    if (res.data.code == 200) {
                        setState({ ...state, data: res.data.data, error_message: res.data.message });



                    } else {
                        alert("Resultados no encontrados.");
                    }
                }).catch(err => {
                    console.log(err.response)
                    setState({ ...state, modal_error_show: true, error_message: err.response.data.message });
                })
            }

        } catch (error) {
            setState({ ...state, modal_error_show: true, error_message: "Algo ha salido mal, por favor vuelva a intentarlo" });
        }
    }
    const handleInputChange = ({ target }) => {
        console.log(target.value);
        setState({ ...state, guide: target.value });
    }

    // let orders= [];
    //    console.log("hola", );
    // .map((ordens, index) => (
    console.log(state.data);
    //       ));

    console.log(state.info_data);

    return (
        <div className="row h-30 py-3 mb-1 px-3 bg-white">
            {(state.info_data === false) ? (
                <><div className='row justify-content-center w-100'>
                    <div className='col-10 col-xs-10 col-sm-10 col-md-4 col-lg-4 col-xl-4'>
                        <form onSubmit={handleSubmit}>
                            <h2>Tracking de ordenes</h2>
                            <div className="row form-group h-100">
                                <label>Ingrese la placa para realizar la busqueda</label>
                                <br /><br />
                            </div>
                            <div className="container">
                                <div className="input-group">
                                    <input
                                        placeholder="Placa"
                                        className="form-control"
                                        type="text"
                                        name="guide"
                                        autoComplete="nope"
                                        value={guide}
                                        onChange={handleInputChange}>
                                    </input>
                                    <span className="input-group-btn">
                                        <button className="btn btn-search color-app-bg" type="submit">Buscar
                                        </button>
                                    </span>
                                </div>
                            </div>

                        </form>
                    </div>
                </div><br /></>
            ) : (


                <> <div className='row justify-content-center w-100'>
                    <div className='col-10 col-xs-10 col-sm-10 col-md-4 col-lg-4 col-xl-4'>
                        <form onSubmit={handleSubmit}>
                            <h2>Tracking de ordenes</h2>
                            <div className="row form-group h-100">
                                <label>Ingrese la placa para realizar la busqueda</label>
                                <br /><br />
                            </div>
                            <div className="container">
                                <div className="input-group">
                                    <input
                                        placeholder="Placa"
                                        className="form-control"
                                        type="text"
                                        name="guide"
                                        autoComplete="nope"
                                        value={guide}
                                        onChange={handleInputChange}>
                                    </input>
                                    <span className="input-group-btn">
                                        <button className="btn btn-search color-app-bg" type="submit">Buscar
                                        </button>
                                    </span>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

                    <div className="row border-bottom py-10 border-blue">
                        <div className="col-12 d-flex justify-content-start font-weight-bold ">
                            <p className="p py-1 fw-bold ">
                                Orden
                            </p>
                        </div>
                    </div>
                    <div className="row h-80 py-2 font-size-detail overflow-auto border-bottom py-10 border-gray">

                        <div className="row py-2 px-4 h-30">
                            <div className='col-4 col-sm-5 d-flex justify-content-start col-no-pl fw-bold'>
                                <div style={{fontSize:14}}>
                                    Placa: {state.data.order_data.code}
                                </div>
                            </div>
                            
                        </div>
                        <div className="row py-2 px-4 h-30">
                            <div className='col-4 col-sm-5 d-flex justify-content-start col-no-pl fw-bold'>
                                <div style={{fontSize:14}}>
                                    Agente: {state.data.order_data.external_agent}
                                </div>
                            </div>
                            
                        </div>
                        <div className="row py-2  px-4 h-30">
                            <div className='col-4 col-sm-5 d-flex justify-content-start col-no-pl fw-bold'>
                                <div style={{fontSize:14}}>
                                    Fecha Servicio: {state.data.order_data.date}
                                </div>
                            </div>
                            
                        </div>
                        <div className="row py-2 px-4 h-30">
                            <div className='col-4 col-sm-5 d-flex justify-content-start col-no-pl fw-bold'>
                                <div style={{fontSize:14}}>
                                    Observación: {state.data.order_data.observation}
                                </div>
                            </div>
                            
                        </div>


                    </div>

                    <div className="  pl-1 py-2   bg-white border-bottom py-10 border-gray ">
                        <div className="row h-7 border-bottom border-gray justify-content-between m-0 p-0">
                            <div className="col-7 d-flex justify-content-start font-weight-bold p-0">
                                <p className="p py-1 fw-bold ">
                                    Repuestos
                                </p>
                            </div>
                        </div>
                        <div className=' justify-content-center container-flow-tracking h-100 w-70 py-3 px-5 '>
                            <table className='table table-hover table-responsive'>
                                <thead>
                                    <tr>
                                        
                                        <th>
                                            Nombre
                                        </th>
                                        <th>
                                            Cantidad
                                        </th>
                                        <th>
                                            Estado
                                        </th>
                                        <th>
                                            Fecha tentativa de llegada
                                        </th>
                                        <th>
                                            Observación
                                        </th>

                                    </tr>

                                </thead>
                                <tbody>
                                    {
                                        (state.data.spare_parts.length > 0) ? (
                                            state.data.spare_parts.map((spare, index) => (
                                                ((spare.delete === "Publicada") ?
                                                (
                                                <tr key={index}>
                                                   
                                                    <td>{spare.name}</td>
                                                    <td>{spare.quantity}</td>
                                                    <td>{(spare.id_status === 1) ? ("Ingresado") :
                                                        (spare.id_status === 2) ? ("Comprado a proveedor") :
                                                            (spare.id_status === 3) ? ("Pendiente de entrega") :
                                                                (spare.id_status === 4) ? ("Bodega EEUU / ES") :
                                                                    (spare.id_status === 5) ? ("Tránsito: Aduana Ecuador") :
                                                                        (spare.id_status === 6) ? ("Tránsito: :Proceso observado") :
                                                                            (spare.id_status === 7) ? ("Bodega Quito") :
                                                                                (spare.id_status === 8) ? ("Despachado a cliente") :
                                                                                    (spare.id_status === 9) ? ("Tránsito Urbano") :
                                                                                        (spare.id_status === 10) ? ("Tránsito Local") :
                                                                                            (spare.id_status === 11) ? ("Entregado") : ("Tentativo")

                                                    }</td>
                                                    <td>{spare.date}</td>
                                                    <td>{spare.observation}</td>
                                                    
                                                </tr>
                                                ):
                                                (<div/>)
                                                )
                                            ))

                                        ) : (
                                            <div>No se ha encontrado registros</div>
                                        )}
                                </tbody>



                            </table>


                        </div>


                    </div>

                </>



            )}
           
        </div>
    )
}
export default OrderDetail;