
import './App.css';
import AppProvider from './context/AppContext';
import Search from './pages/Search';
import Footer from './components/Footer';

function App() {
  return (
    <AppProvider>
  <div className="App">
        <Search></Search>
        <Footer></Footer>
      </div>
    </AppProvider>
    
  );
}

export default App;
