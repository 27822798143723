import { MDBFooter } from 'mdb-react-ui-kit';
const Footer = () => {
    return (

        <MDBFooter bgColor='light' className='text-center text-lg-left'>
        <div className='text-center p-3 footer color-app-bg' >
        Copyright  <span dangerouslySetInnerHTML={{ "__html": "&copy;" }} /> RepSeg - Todos los derechos reservados
        </div>
      </MDBFooter>


    )
}
export default Footer;


 