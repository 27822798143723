import API from './config';

const ENDPOINTS = {
  get_orders: "api/v1/order/get_by_plate/",
}
 
const useApi = {

  get_by_plate: (plate) => new Promise(
    (resolve, reject) => {
       API.get(ENDPOINTS.get_orders+plate)
        .then(
          res => res
        )
        .then(
          data => resolve(data)
        )
        .catch(
          err => reject(err)
        )
    }
  ),
      
}

export default useApi;