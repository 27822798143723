import React from 'react'

export const AppContext = React.createContext()

const AppProvider = (props) => {

    const states = {
        order: [],
        spare_parts:[],

    }
    const [state, setState] = React.useState(states);
    
    //const {events} = calendarState;

    const setOrder = (order) => {
        
        setState({...state, order:order});
       
    }

    const getOrder = () => {
        return state.order;
    }

    const setSpareParts = (spare_parts) => {
        
        setState({...state, spare_parts:spare_parts});
       
    }

    const getSpareParts = () => {
        return state.spare_parts;
    }

    const setCurrentScreen = (current_screen) => {
        
        setState({...state, current_screen:current_screen});
       
    }

    const getCurrentScreen = () => {
        return state.current_screen;
    }

    const setAppErrorMessages = (message_error, error_id) => {
        let errors=[];
        let all_errors = state.messages_error;
        if(all_errors.length>0){
            errors = all_errors.filter(message => message.id!==error_id);
            
        }
        errors.push(message_error);
        setState({...state, messages_error:errors});
       
    }
    const deleteAppErrorMessages = (error_id) => {
        
        let errors = state.messages_error.filter(message => message.id!==error_id);
        setState({...state, messages_error:errors});
       
    }
    const getAppErrorMessages = () => {
        return state.messages_error;
        
    }
    

    return (
        <AppContext.Provider value={{state, setAppErrorMessages, getAppErrorMessages, deleteAppErrorMessages, setCurrentScreen, getCurrentScreen}}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppProvider;
