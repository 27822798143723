import React from 'react'
import SearchGuideForm from '../components/forms/SearchGuideForm';
import Header from '../components/Header';
import Order from '../components/Order';

const Search = () => {
  return (
    <div>
        <Header></Header> 
        <Order></Order>
        
    </div>
  )
}
export default Search;